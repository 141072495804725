<template>

<app-content :loading="is.loading">

	<app-content-head title="Settings" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :tabs="tabs">

			<template v-slot:contests>

				<app-input-checklist :collapsed="true" :fullWidth="true" :singular="true" :columns="1" label="Visibility" v-model="model.schedule.contests.enabled" :validation="$v.model.schedule.contests.enabled" :options="$constants.schedule.settings.contests.enabledName" :descriptions="$constants.schedule.settings.contests.enabledDescription" />
				
			</template>

			<template v-slot:lobbies>

				<app-input-checklist :collapsed="true" :fullWidth="true" :singular="true" :columns="1" label="Visibility" v-model="model.schedule.lobbies.enabled" :validation="$v.model.schedule.lobbies.enabled" :options="$constants.schedule.settings.lobbies.enabledName" :descriptions="$constants.schedule.settings.lobbies.enabledDescription" />
				
			</template>

			<template v-slot:mods>

				<app-input-suggest :multiple="true" label="Moderators" placeholder="Search badge" notes="Specify badge holders that can create official and ticketed events, submit events even when closed, and use private rooms, event types, and lobbies." v-model="model.schedule.moderators" api="convention/badge" :validation="$v.model.schedule" />
			
			</template>

			<template v-slot:submit>

				<app-input-checklist :collapsed="true" :fullWidth="true" :singular="true" :columns="1" label="Visibility" v-model="model.schedule.events.submit.enabled" :validation="$v.model.schedule.events.submit.enabled" :options="$constants.schedule.settings.events.submit.enabledName" :descriptions="$constants.schedule.settings.events.submit.enabledDescription" />
				<app-input-text label="Max. Lobbies" placeholder="Enter limit..." :toggle="model.schedule.events.submit.lobbies.max.enabled" notes="Set a maximum number of lobbies that can be linked to an event." v-on:toggle="model.schedule.events.submit.lobbies.max.enabled = !model.schedule.events.submit.lobbies.max.enabled" v-model="model.schedule.events.submit.lobbies.max.limit" :validation="$v.model.schedule.events.submit.lobbies.max.limit" />
				<app-input-date v-if="model.schedule.events.submit.enabled === $constants.schedule.settings.events.submit.enabled.timed" :time="true" label="Submit from" placeholder="Enter date..." :toggle="model.schedule.events.submit.from.enabled" v-on:toggle="model.schedule.events.submit.from.enabled = !model.schedule.events.submit.from.enabled" v-model="model.schedule.events.submit.from.date" :validation="$v.model.schedule.events.submit.from.date" />
				<app-input-date v-if="model.schedule.events.submit.enabled === $constants.schedule.settings.events.submit.enabled.timed" :time="true" label="Submit until" placeholder="Enter date..." :toggle="model.schedule.events.submit.to.enabled" v-on:toggle="model.schedule.events.submit.to.enabled = !model.schedule.events.submit.to.enabled" v-model="model.schedule.events.submit.to.date" :validation="$v.model.schedule.events.submit.to.date" />
				<app-input-text v-if="$util.in(model.schedule.events.submit.enabled, $constants.schedule.settings.events.submit.enabled, ['timed', 'closed'])" :autogrow="true" label="Closed Message" notes="Message shown on the submit page to attendees when submissions are closed." v-model="model.schedule.events.submit.content" :validation="$v.model.schedule.events.submit.content" placeholder="Enter message" />
			
			</template>

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/schedule/access', 'read'),
			settings: true,
			tabs: [
				{ name: 'contests', text: 'Contests' },
				{ name: 'lobbies', text: 'Lobbies' },
				{ name: 'mods', text: 'Moderators' },
				{ name: 'submit', text: 'Submit Event' }
			],
			model: {
				schedule: {
					moderators: [],
					contests: {
						enabled: 1
					},
					lobbies: {
						enabled: 1
					},
					events: {
						submit: {
							enabled: 1,
							from: {
								enabled: false,
								date: 0
							},
							to: {
								enabled: false,
								date: 0
							},
							content: '',
							lobbies: {
								max: {
									enabled: false,
									limit: 1
								}
							}
						}
					}
				}
			},
			submitOptions: {
				0: 'Closed',
				1: 'Open'
			}
		}

	},

	validations: {
		model: {
			schedule: {
				contests: {},
				lobbies: {},
				events: {
					submit: {
						from: {},
						to: {},
						lobbies: {
							max: {}
						}
					},
				}
			}
		}
	}

}

</script>

<style scoped>

</style>